<template>
<div v-if="expos.length > 0" class="mb-5 mt-5 pageexpos" style="padding-bottom:100px;">

    <div class="main-bg-img dessus"></div>

    <v-row class="mt-5 pt-2">
      <v-col cols="11" sm="11" class="px-0 mx-auto" style="z-index:1!important;">
        <h1 class="d-none">Mes expositions</h1>
        <div class="font-flower">
          <v-btn @click="goBack()" fab small color="grey darken-3" class="mr-5"
                  title="retour à la page précédente">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
          <span class="d-none d-sm-inline" style="font-size:50px;">Mes expositions</span>
          <span class="d-sm-none" style="font-size:30px;">Mes expos</span>
        </div>
        <v-col cols="12" md="8" lg="7" class="pt-0">
          <!-- <p>Retrouvez l'ensemble de mes réalisations, depuis mes débuts...
            <!- - <br>
            Si vous souhaitez commander votre propre mandala personnalisé, je vous invite à faire un tour dans 
            <router-link to="/commande">l'atelier</router-link> 
            pour découvrir les choix disponibles... - ->
            <br>
          </p> -->
        </v-col>
        <br>
          <template v-for="(expo) in expos">
            <v-row :key="expo.name">
              <v-col cols="12" class="pa-0 mt-5">
                <v-divider></v-divider>
                <v-card color="transparent" elevation="0" class="my-5">
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="5" 
                          class="px-5 pb-0 mt-5 text-center">
                      <router-link :to="'/expo/'+expo._id" v-if="expo.image">
                        <v-img class="mx-auto" contain
                               :src="baseUrl() + expo.image">
                        </v-img>
                      </router-link>
                      <v-carousel v-else>
                        <v-carousel-item
                          v-for="(image,i) in expo.gallery"
                          :key="i" :src="baseUrl() + image"
                          reverse-transition="fade-transition"
                          transition="fade-transition" :to="'/expo/'+expo._id"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="7" 
                          class="px-5 mt-5 text-left">
                    <router-link :to="'/expo/'+expo._id">
                      <v-card-title class="font-dancing pt-0 pb-5 mb-3 expo-name">
                        <v-icon large class="mb-2">mdi-artboard</v-icon>
                        {{ expo.name }}
                        <v-btn fab small color="blue"><v-icon>mdi-arrow-right</v-icon></v-btn>
                      </v-card-title>
                    </router-link>
                    <br><v-divider></v-divider><br>
                      <v-card-title class="font-dancing pt-0 pb-0 expo-date">
                        <v-icon large class="mb-2">mdi-calendar</v-icon>
                        {{ dateToLocalStr(expo.dateEvent) }}
                      </v-card-title><br v-if="expo.addresse">
                      <v-card-title class="font-dancing pt-0 pb-0 expo-address" v-if="expo.addresse">
                        <v-icon large class="mb-2">mdi-map-marker</v-icon>
                        {{ expo.addresse }}
                      </v-card-title>
                      <br>
                      <v-divider class="mt-4"></v-divider>
                      <v-card-text v-html="nl2br(expo.description)"></v-card-text>
                      <!-- <br><small>{{ product.description.substr(0, 50) }}...</small> -->
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        
      </v-col>
    </v-row>

  </div>
  <div v-else-if="!loading" class="mb-5 mt-md-5 text-center" style="padding-bottom:100px;">
    <v-row>
      <v-col cols="12" md="6" class="mx-auto">
        <v-card>
          <v-col>
            <v-card-title>Aucune exposition prévue pour le moment.</v-card-title>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else class="mb-5 mt-md-5 text-center" style="padding-bottom:100px;">
    <v-row>
      <v-col cols="12" md="6" class="mx-auto">
        <v-btn x-large fab loading></v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.v-application .pageexpos a{
  color:unset;
}
.pageexpos .main-bg-img.dessus{
  opacity: 0.1;
}
.pageexpos .v-card__title.expo-name{
  font-size:40px;
}
.pageexpos .v-card__title.expo-date{
  font-size:30px;
}
.pageexpos .v-card__title.expo-address{
  font-size:27px;
}

@media (max-width: 960px) {
.pageexpos .v-card__title.expo-name{
  font-size:25px;
  padding-bottom:0px!important;
}
.pageexpos .v-card__title.expo-date{
  font-size:20px;
}
.pageexpos .v-card__title.expo-address{
  font-size:20px;
}
  
}

</style>

<script>

//import axios from 'axios'
import router from '../router/router'
import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    expos: [],
    loading: true,
  }),
  async mounted(){
    this.initData()
  },
  methods: {
    async initData(){
      await this.$store.dispatch('app/fetchEntities', 
                        { entityType: "expo", 
                          query: { public: true }, 
                          sort: { 'created' : -1 } 
                        })
      this.expos = this.$store.state.app.data.expo
      this.loading = false
    },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },
    baseUrl(){ return core.baseUrl() },
    goBack(){ router.back() },
    dateToLocalStr(date){
      let d = new Date(date)
      return d.toLocaleDateString('fr-FR', { weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })
    }

  },
  computed: {
    
    
  }
}
</script>
